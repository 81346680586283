import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Наші послуги | Наші футбольні послуги на Зеленому полі
			</title>
			<meta name={"description"} content={"Створюй свою гру, керуй своєю командою - The Green Pitch, послуги, створені для чемпіонів "} />
			<meta property={"og:title"} content={"Наші послуги | Наші футбольні послуги на Зеленому полі"} />
			<meta property={"og:description"} content={"Створюй свою гру, керуй своєю командою - The Green Pitch, послуги, створені для чемпіонів "} />
			<meta property={"og:image"} content={"https://ua.pixvos.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ua.pixvos.com/img/946916.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ua.pixvos.com/img/946916.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ua.pixvos.com/img/946916.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ua.pixvos.com/img/946916.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ua.pixvos.com/img/946916.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ua.pixvos.com/img/946916.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="64px 0" sm-padding="40px 0" font="--base" color="--dark">
			<Override slot="SectionContent" align-items="center" />
			<Text
				as="h1"
				font="--headline1"
				md-font="--headline2"
				max-width="520px"
				margin="0 auto 5px auto"
				text-align="center"
			>
				Наші послуги
			</Text>
			<Text
				as="p"
				color="--grey"
				max-width="920px"
				margin="0 auto"
				text-align="center"
				font="--lead"
			>
				Green Pitch - це більше, ніж поле, це повністю обладнаний футбольний комплекс, де оживає дух гри. Наш спектр послуг гарантує, що кожне відвідування - це крок до величі, незалежно від того, чи це звичайна гра, чи змагальні матчі.
			</Text>
			<Box
				margin="40px -16px -16px -16px"
				display="grid"
				flex-wrap="wrap"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="32px"
				md-grid-template-columns="repeat(2, 1fr)"
				lg-grid-gap="24px"
				md-grid-gap="16px"
				sm-grid-template-columns="1fr"
				sm-grid-gap="32px"
				width="100%"
			>
				<Box display="flex" flex-direction="column">
					<Box
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://ua.pixvos.com/img/4.jpg) 50% 0/cover no-repeat"
						border-radius="8px"
					/>
					<Text as="h3" font="normal 500 20px/1.2 --fontFamily-sans" margin="5px 0 20px 0" sm-margin="5px 0 0 0">
						Оренда поля
					</Text>
					<Text as="p" margin="0px 0 5px 0" font="--base" color="--grey">
						Індивідуальна гра: Зарезервуйте свій слот для особистих тренувань або спонтанної гри. Відточуй дриблінг, відточуй удари та насолоджуйся грою у власному темпі.
						<br /><br />
						Командні тренування: Наше поле доступне для командних тренінгів, що є ідеальним майданчиком для стратегічних сесій, покращення командної роботи та суворих тренувань.

					</Text>
				</Box>
				<Box display="flex" flex-direction="column">
					<Box
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://ua.pixvos.com/img/5.jpg) 50% 0/cover no-repeat"
						border-radius="8px"
					/>
					<Text as="h3" font="normal 500 20px/1.2 --fontFamily-sans" margin="5px 0 20px 0" sm-margin="5px 0 0 0">
						Оренда обладнання
					</Text>
					<Text as="p" margin="0px 0 5px 0" font="--base" color="--grey">
						Одягай спорядження: Немає бутс? Нічого страшного. Орендуйте у нас найновішу футбольну екіпіровку і будьте завжди готові до гри.
						<br /><br />
						Тренувальний інвентар: Від драбинок до конусів - у нас є все необхідне, щоб вивести ваші тренування на новий рівень.

					</Text>
				</Box>
				<Box display="flex" flex-direction="column">
					<Box
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://ua.pixvos.com/img/6.jpg) 50% 0/cover no-repeat"
						border-radius="8px"
					/>
					<Text as="h3" font="normal 500 20px/1.2 --fontFamily-sans" margin="5px 0 20px 0" sm-margin="5px 0 0 0">
						Послуги тренера
					</Text>
					<Text as="p" margin="0px 0 5px 0" font="--base" color="--grey">
						Професійні тренування: покращуйте свою гру з нашими досвідченими тренерами, які пропонують заняття для всіх рівнів підготовки - від гравців-початківців до професіоналів-початківців.
						<br /><br />
						Молодіжні клініки: Познайомте своїх дітей з футболом за допомогою наших веселих та цікавих клінік, покликаних розпалити пристрасть до спорту на все життя.

					</Text>
				</Box>
				<Box display="flex" flex-direction="column">
					<Box
						height="0"
						margin="0 0 20px 0"
						padding-bottom="100%"
						background="url(https://ua.pixvos.com/img/7.jpg) 50% 0/cover no-repeat"
						border-radius="8px"
					/>
					<Text as="h3" font="normal 500 20px/1.2 --fontFamily-sans" margin="5px 0 20px 0" sm-margin="5px 0 0 0">
						Спеціальні заходи
					</Text>
					<Text as="p" margin="0px 0 5px 0" font="--base" color="--grey">
						Ліги іменинників: Відсвяткуйте день народження на футбольній вечірці, де веселощі поєднуються з дружнім змаганням
						<br /><br />
						Корпоративні кубки: Зміцніть командний дух і гордість компанії за допомогою наших послуг з проведення корпоративних турнірів

					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Contact />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});